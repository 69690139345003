import {Container} from "react-bootstrap";
import React from "react";

export const Eventpad: React.FC = () => {
    return (<Container>
            <iframe
                width="100%"
                height="1000px"
                marginHeight={0}
                marginWidth={0}
                // srcrolling="no"
                src="sites/Eventpad - The Notepad Editor for Event Data _!--- Home--_.html" title="Eventpad" />
        </Container>
        );
}
