import {Card, Col} from "react-bootstrap";
import React, {PropsWithChildren} from "react";

export type VideoCardItem = {
    name: React.ReactNode,
    video: React.ReactNode,
}

export interface VideoCardProps extends PropsWithChildren<any> {
    item: VideoCardItem
}

export const VideoCard: React.FC<VideoCardProps> = (props) => {
    return (<Card>
                    <Col md={6}>
                        <Card.Header style={{
                            height: "300px",
                            width: "200%",
                            objectFit: "contain",
                            padding: "10px",
                            borderBottom: "1px solid rgb(223,223,223)"
                        }}>
                            {props.item.video}
                        </Card.Header>
                        <Card.Body>
                            <Card.Title>{props.item.name}</Card.Title>
                        </Card.Body>
                    </Col>
            </Card>);
}
