import {Col, Container, FormCheck, Row} from "react-bootstrap";
import React from "react";
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {Section} from "../components/Section";
import {CVCard, CVCardItem, CVType, cvTypes} from "../components/views/CVCard";
import {faBriefcase, faGraduationCap, faHandHoldingHeart, faTrophy} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'


export const Curiculum: React.FC = () => {
    const [filters, setFilters] : [CVType[], any] = React.useState(cvTypes);

    const cvCards: CVCardItem[] = [
        {
            name: "Innovation award Energy solution",
            positition: "Revolutionary scaleable software algorithm for energy market",
            period: "2024",
            img: "prizes/vakbeurs.jpg",
            type: "award",
            tags: ["Innovation", "Research", "Energy Management"],
            activity: <span>
                Our algorithm Alice is recognized as <br/> <b>Revolutionary scaleable technology for Energy Management</b>!
                Check out the article <a href={"https://www.vakbeursenergie.nl/nl/nieuws-item/AI-algoritme-Alice-bekroond-met-Innovative-Energy-Solutions-Award/"}>here</a>.
            </span>
        },
        {
            name: "Gerard & Anton Awards",
            positition: "Most promising Tech start-up in the BrainPort Region",
            period: "2023",
            img: "prizes/gaawards.jpg",
            type: "award",
            tags: ["Startup", "Talent", "Energy Management"],
            activity: <span>
                Every year the BrainPort region identifies the top 10 most promising tech scale-ups and Tibo Energy was selected!<br/>
                Check out the article <a href={"https://innovationorigins.com/en/10-1-gerard-anton-awards-here-are-the-2023-winners/"}>here</a>.
            </span>
        },
        {
            name: "Eindhoven University of Technology",
            positition: "EAISI Profession Education Teacher",
            period: "2022-Present",
            img: "media/eaisi-logo.png",
            type: "work",
            tags: ["Research", "Anomaly Detection", "Visual Analytics", "Event visualization"],
            activity: <span>Teacher Anomaly Detection and Visual Analytics and designer of E-modules for entrepreneurs and company-level education. <a href="https://www.tue.nl/en/research/institutes/eindhoven-artificial-intelligence-systems-institute/ai-education/professional-education/eaisi-academy">More information on EAISI.</a></span>
        },
        {
            name: "Tibo Energy",
            positition: "Visualization Architect",
            period: "2022-Present",
            img: "media/tibo_logo.jpeg",
            type: "work",
            tags: ["Startup", "Energy Management", "Orchestration"],
            activity: <span>Software development to enable peak-shaving and load-balancing in heterogenous energy networks. <a href="https://www.tibo.energy.">More information on TIBO Energy.</a></span>
        },
        {
            name: "CodeNext21",
            positition: "Visualization Architect",
            period: "2021-Present",
            img: "media/codenext21.svg",
            type: "work",
            tags: ["Startup", "Machine learning"],
            activity: <span>Software development of transparent machine learning platform. <a href="https://www.codenext21.com">More information on CodeNext21.</a></span>
        },
        {
            name: "Best Teacher Data Science and Artificial Intelligence",
            positition: "Nomination",
            period: "2022",
            img: "prizes/tue.png",
            type: "award",
            tags: ["DataScience", "Teacher"],
            activity: <span>
                Together with prof. dr. Anna Vilanova we have been nominated for the best Teacher in the Data-Science & Artificial Intelligence program!
            </span>
        },
        {
            name: "DutchDataBusters",
            positition: "Supervisor",
            period: "2018-Present",
            img: "media/ddb.png",
            type: "charity",
            tags: ["Education", "Students", "Industry", "DataScience"],
            activity: <span>Connecting Master students and Industry in the field of Data Science and Data transformation.</span>
        },
        {
            name: "Eindhoven University of Technology",
            positition: "Teacher",
            period: "2021-Present",
            img: "prizes/tue.png",
            type: "work",
            tags: ["Education", "Visual Analytics", "Event visualization"],
            activity: <span>Teaching Master-level Visual Analytics for Computer Science and Data Science programs</span>
        },
        {
            name: "Elsevier Weekblad: 30 onder de 30",
            positition: "Jonge uitblinkers om in de gaten te houden",
            period: "2020",
            img: "media/ew30.jpg",
            type: "award",
            tags: ["Talent"],
            activity: <span>
                Every year Elsevier's Weekblad highlights 30 people under the age of 30 who have done remarkable work given their age.<br />
                Deeply honored to be chosen as part of this group!<br/>
                Check out the magazine <a href={"https://mdrresearch.nl/wordpress/wp-content/uploads/27_NED-30-ONDER-30-PLUS-3.pdf"}>here</a>.
            </span>
        },
        {
            name: "AnalyzeData",
            positition: "Co-Founder/Visualization Architect",
            period: "2018-2021",
            img: "media/analyzedata.jpg",
            type: "work",
            tags: ["Startup"],
            activity: <span>Software development to detect and prevent Voice Over IP Fraud.</span>
        },
        {
            name: "NWO TakeOff Fase 2 Grant",
            positition: "Grant",
            period: "2019",
            img: "prizes/takeoff.jpg",
            type: "award",
            tags: ["Grant"],
            activity: <span>
                The Dutch Scientific Institute for Research encouraged the valorization of scientific results to Industry and awarded our company with this grant.
            </span>
        },
        {
            name: "TU/e Academic Awards",
            positition: "Best Data-Science Thesis",
            period: "2019",
            img: "prizes/tue.png",
            type: "award",
            tags: ["DataScience", "Thesis"],
            activity: <span>
                My thesis is nominated for the TU/e Academic awards 2019!
                The thesis is chosen as the best <a href={"https://flippingbooks.tue.nl/internet/academic_awards_2019/34/"}>PhD thesis of Mathematics and Computer Science</a> department
            </span>
        },
        {
            name: "TU/e Academic Awards",
            positition: "Best Data-Science Thesis",
            period: "2019",
            img: "prizes/tue.png",
            type: "award",
            tags: ["DataScience", "Thesis"],
            activity: <span>
                My thesis is nominated for the TU/e Academic awards 2019!
                The thesis is chosen as the best <a href={"https://flippingbooks.tue.nl/internet/academic_awards_2019/34/"}>PhD thesis of Mathematics and Computer Science</a> department
            </span>
        },
        {
            name: "Gerrit van Dijk Data Science",
            positition: "Honorable mention Best DataScience Thesis",
            period: "2019",
            img: "prizes/gerrit.png",
            type: "award",
            tags: ["DataScience", "Thesis"],
            activity: <span>
                My Thesis Interactive Visualization of Event Logs for Cybersecurity has won an Honorable mention for the <a href={"https://www.nederlandsedatascienceprijzen.nl/"}>Gerrit van Dijk</a> prijs 2019!!!<br />
                Check out the <a href={"https://www.linkedin.com/feed/update/urn:li:activity:6546673076246056960"}>article</a>.
            </span>
        },
        {
            name: "Eindhoven University of Technology",
            positition: "Post-Doc Researcher",
            period: "2018-2021",
            img: "prizes/tue.png",
            type: "work",
            tags: ["Research", "Security", "Visualization", "PCAP analysis", "Event visualization"],
            activity: <span>Researching new way to enable Event visual analytics in Security</span>
        },
        {
            name: "European Venture Program",
            positition: "Best Executive Summary",
            period: "2018",
            img: "prizes/evp.png",
            type: "award",
            tags: ["Startup", "EventPad"],
            activity: <span>
                5 countries, 22 startups, 1 best paper. <br/>
                EventPad has won the best 2-pager award at the European Venture Program 2018! Read more about the program <a href={"https://innovationorigins.com/nl/munchense-startup-wint-eurotech-pitchwedstrijd-in-eindhoven/"}>here</a>.
            </span>
        },
        {
            name: "Security Operations Center KPN",
            positition: "Researcher",
            period: "2016-2018",
            img: "media/kpn.png",
            type: "work",
            tags: ["Network analysis", "Security", "Visualization"],
            activity:
                <span>Application of developed security tooling to improve monitoring of critical communication backbone.</span>
        },
        {
            name: "ICT.Open 2018 Best Demo Award",
            positition: "1st place",
            period: "2018",
            img: "prizes/ictopenaward.png",
            type: "award",
            tags: ["Eventpad", "NWO"],
            activity: <span>
                EventPad won the <a href={"https://www.tue.nl/en/our-university/departments/wiskunde-en-informatica/news/28-03-2018-tue-phd-candidates-win-ictopen-award-with-visual-analytics-system-eventpad/"}>ICTOpen</a> 2018 award for Best Demo and Technology,<br/>
                The Dutch Scientific Institute NWO announced Eventpad as the most impactful Dutch technology for ICT environments and industries of 2018!
            </span>
        },
        {
            name: "Motto Communications",
            positition: "Researcher",
            period: "2016-2019",
            img: "media/motto.jpg",
            type: "work",
            tags: ["PCAP analysis", "C++", "SIP", "Visualization"],
            activity:
            <span>Development of new visualization techniques for the detection of toll fraude in VoIP traffic. <a href="https://www.motto.nl/home">More information on Motto.</a></span>
        },
        {
            name: "IEEE Symposium on Visualization for CyberSecurity",
            positition: "Progam Committee member",
            period: "2016-present",
            img: "media/vizsec.png",
            type: "charity",
            tags: ["Security", "Visualization"],
            activity:<a href={"http://vizsec.org/cfp/"}>More information on VizSec 2016.</a>
        },
        {
            name: "IEEE Visual Analytics Science and Technology Challenge 2017",
            positition: "1st place",
            period: "2017",
            img: "prizes/vast2.jpg",
            type: "award",
            tags: ["Visual Analytics"],
            activity: <span>
                EventPad has won the VAST Challenge 2017 award for <b>Elegant Support For Hypothesis Generation and Testing!</b>
                <br/>
                Check out my paper: <a href={"papers/lekagul.pdf"}>Exploring Lekagul Sensor Events using Rules, Aggregations, and Selections</a></span>
        },
        {
            name: "Eindhoven University of Technology",
            positition: "PhD Candidate",
            period: "2014-2018",
            img: "media/spyspot.png",
            type: "education",
            tags: ["Security", "Visualization", "PCAP", "Anomaly Detection", "Intrusion Detection", "Qt", "C++"],
            activity: <span>PhD candidate in the Visualization group in the Department of Mathematics and Computer Science. Project <a href={"/home"}>SpySpot</a></span>
        },
        {
            name: "T.i.M.e.S. foundation",
            positition: "Team member/Volunteer",
            period: "2016-2018",
            img: "media/times.png",
            type: "charity",
            tags: [],
            activity:<span>
                Volunteer in the development and organization of a (semi-)yearly gaming events of up to 300 people. <a href={"https://times-lan.nl/"}>More information on TiMeS.</a>
            </span>
        },
        {
            name: "Eindhoven University of Technology",
            positition: "MSc. in Computer Science & Engineering",
            period: "2012-2014",
            img: "prizes/tue.png",
            type: "education",
            tags: ["Java", "TypeScript", "HTML", "PHP", "SQL", "NoSQL", "Python", "C", "Assembly"],
            activity: <span>
                <b>Program</b>: Computer Science & Engineering.
                <br />
                <b>Specialization</b>: Software Engineering & Technology
                <br/>
                <br/>
                <i>Achievements:</i> <b>Cum Laude</b>, current average 8.9 out of 10.0
                <br/>
                <i>Expertise:</i> <br/>
                - Software Engineering<br/>
                - Formal methods <br/>
                - Visualization
                <br/>
                <i>Extracurriculum activities:</i> Participated in the <a href={"http://www.tue.nl/en/education/tue-graduate-school/masters-programs/industrial-and-applied-mathematics/degree-structure/honors-program/"}>TU/e Honors Program</a>
            </span>
        },
        {
            name: "Enterprise information system VKW",
            positition: "Lead software architect & developer",
            period: "2009-2014",
            img: "media/vkw.png",
            type: "work",
            tags: ["FoxPro", "BPMS"],
            activity:<span>
                <i>Working Hours:</i> Spare time besides education (varies)
                <br/>
                <i>Additional information:</i> <br/>
                - Participation in the full development cycle with a team of two programmers.
            </span>
        },
        {
            name: "Eindhoven University of Technology",
            positition: "BSc. in Computer Science",
            period: "2009-2012",
            img: "prizes/tue.png",
            type: "education",
            tags: ["Java", "JavaScript", "HTML", "PHP", "SQL", "NoSQL", "Python", "C", "Assembly"],
            activity: <span>
                 <i><b>Major</b>:</i> Computer Science & Engineering
                <br/>
                 <i><b>Minor</b>:</i> Connecting Intelligence (Faculty of Electrical Engineering)
                <br/>
                <br/>
                <i>Achievements:</i> <b>Cum Laude</b>, current average 8.9 out of 10.0
                <br/>
                <br/>
                <i>Graduation:</i> Design and development of DiTriS: a cross-platform distributed information triple store database with a team consisting of eight students.
                <br/>
                <br/>
                Participated in the full development cycle and primarily involved in requirements engineering, system design, and development.
            </span>
        },
        {
            name: "Freelance Consultancy and Training",
            positition: "Freelance projects",
            period: "2011-2018",
            img: "media/typing.png",
            type: "work",
            tags: ["MS Office", "Computer Science", "Excel VBA"],
            activity:<span>
                <i>Working Hours:</i> Residual time besides education (varies)
                <br/>
                <i>Additional information:</i> <br/>
                - Consultant and developer of (excel) automation tools to enhance the interconnectivity of business processes.
                <br/>
                - Freelance course designer for applications and techniques/tooling in the area of computer science.
            </span>
        },
        {
            name: "Freelance Web Designer",
            positition: "Freelance projects",
            period: "2011-2018",
            img: "media/typing.png",
            type: "work",
            tags: ["HTML", "CSS", "Javascript", "PHP"],
            activity:<span>
                <i>Working Hours:</i> Residual time besides education (varies)
                <br/>
                <i>Additional information:</i> <br/>
                - Tailor made websites for (temporary) business activities
            </span>
        },
        {
            name: "Web Developer",
            positition: "E-business company Softlution - Hasselt, Belgium",
            period: "2009-2009",
            img: "media/soft.png",
            type: "work",
            tags: ["HTML", "CSS", "Excel VBA", "OpenCMS"],
            activity:<span>
                <i>Working Hours:</i> 40 hours contract (1 month)
                <br/>
                <i>Responsibilities:</i> <br/>
                - Design and development of home webpage Softlution.
                <br/>
                - Design and development of automation macros for administrative tasks.
            </span>
        },
    ];


    return (<Container fluid className="px-lg-4 px-xl-5">
        <Row>
            <Section name={"Curiculum Vitae"}>
                <p>Currently, I am a part-time teacher Visual Analytics at the <a href="https://www.tue.nl/en">Eindhoven University of
                    Technology</a> under
                    supervision of <a href="https://www.tue.nl/en/research/researchers/anna-vilanova/">prof. dr. Anna Vilanova</a> (<a
                        href="https://www.tue.nl/en/research/research-groups/data-science/visualization">Visual Analytics</a>).
                </p>
                <p>
                    I achieved both my bachelors and masters degrees at the Eindhoven University of Technology (TU/e)
                    (both with honors). I completed my master in the area of software engineering (in particular
                    Language engineering). My thesis was on exploring and visualizing the Generalized LL Parsing
                    algorithm.</p>
                <p>
                    I obtained my PhD degree in the area of Data Visualization. The topic of my thesis is <a
                    href={"files/Cappers2018.pdf"}>Interactive Visualization of Event Logs for Cybersecurity</a>. You
                    can
                    find a more detailed CV below.</p>

                <p> An offline version of the Curiculum Vitae can be downloaded <a
                    href="misc/curiculumvitae.pdf">here</a></p>
            </Section>
        </Row>

        <Row>
                {cvTypes.map((type, i) => {
                    return <Col key={i} md={2}>
                        <FormCheck style={{textAlign: "left"}} type="switch">
                            <FormCheck.Input checked={filters.includes(type)} onChange={() => {
                                const copy = [...filters];
                                const index = copy.indexOf(type);

                                if (index > -1) {
                                    copy.splice(index, 1); // 2nd parameter means remove one item only
                                }else{
                                    copy.push(type);
                                }

                                setFilters(copy);
                            }}/>
                            <FormCheck.Label onClick={()=>{}}>
                                {buildIcon(type)} {type}
                            </FormCheck.Label>
                        </FormCheck>
                    </Col>
                })}
        </Row>
        <Row>
            <Col md={12}>
                <VerticalTimeline lineColor={"black"}>

                    {cvCards.filter((item) => filters.includes(item.type)).map((cvCard,i) => {
                        return <VerticalTimelineElement
                            key={i}
                            contentArrowStyle={{borderRight: '7px solid ' + getColor(cvCard)}}
                            date={cvCard.period}
                            iconStyle={{background: getColor(cvCard), color: '#ffffff'}}
                            icon={buildIcon(cvCard.type)}
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: 'rgb(250,250,250)', color: '#252525' }}
                        >
                         <CVCard item={cvCard}/>
                        </VerticalTimelineElement>
                    })}
                </VerticalTimeline>
            </Col>
        </Row>
    </Container>);
}

const buildIcon = (type: CVType): React.ReactNode => {
    switch (type) {
        case "education":
            return <FontAwesomeIcon icon={faGraduationCap}/>;
        // case "research":
        //     return <FontAwesomeIcon icon={faAtom}/>;
        case "award":
            return <FontAwesomeIcon icon={faTrophy}/>;
        case "work":
            return <FontAwesomeIcon icon={faBriefcase}/>;
        default:
            return <FontAwesomeIcon icon={faHandHoldingHeart}/>;
    }
}

const getColor = (cvCard: CVCardItem): string => {
    switch (cvCard.type) {
        case "education":
            return "rgb(27,40,121)";
        // case "research":
        //     return "rgb(33, 150, 243)";
        case "award":
            return "rgb(35,133,0)";
        case "work":
            return "rgb(126,0,0)";
        default:
            return "rgb(208,78,152)";
    }
}
