import {Col, Row} from "react-bootstrap";
import React, {PropsWithChildren} from "react";

export interface GridViewProps extends PropsWithChildren<any> {
    items: React.ReactNode[]
}

export const GridView: React.FC<GridViewProps> = (props) => {
    return (<Row>
        {
            props.items.map((item, i) => {
                return (
                    <Col key={i} md={4} sm={2} style={{width: '18rem'}}>{item}</Col>);
            })
        }
    </Row>);
}
