import React from "react";
import {Section} from "../components/Section";
import {Container} from "react-bootstrap";
import {GridView} from "../components/GridView";
import {LinkCard, LinkCardItem} from "../components/views/LinkCard";

type SectionItem = {
    name: string,
    items: LinkCardItem[]
}

export const Traction: React.FC = () => {
    const websites: LinkCardItem[] = [
        {
            name: "Tibo Energy",
            img: "media/tibo_logo.jpeg",
            url: "https://tibo.energy"
        },
        {
            name: "CodeNext21",
            img: "media/codenext21.svg",
            url: "https://codenext21.com"
        },
        {
            name: "DutchDataBusters",
            img: "media/ddb.png",
            url: "https://dutchdatabusters.nl"
        },
        {
            name: "EventPad",
            img: "media/eventPad.png",
            url: "/eventpad"
        },
    ];

    const grants: LinkCardItem[] = [
        {
            name: <span><span>NWO Demonstrator Grant 2018</span> <br/> <span>Amount: €150.000</span></span>,
            img: "prizes/demonstrator.jpg",
            url: "https://www.nwo.nl/en/researchprogrammes/demonstrator"
        },
        {
            name: <span><span>NWO TakeOff Fase 2 Grant 2019</span> <br/> <span>Amount: €250.000</span></span>,
            img: "prizes/takeoff.jpg",
            url: "https://www.nwo.nl/en/researchprogrammes/take"
        },
        {
            name: <span><span>SET Ventures 3M investment</span> <br/> <span>Amount: €3.000.000</span></span>,
            img: "prizes/set.png",
            url: "https://brainporteindhoven.com/the-gate/nl/nieuws/tibo-energy-haalt-eur3m-kapitaal-op"
        },
    ];

    const awards: LinkCardItem[] = [
        {
            name: <span><span>Vakbeurs Energy Innovation Award 2024</span> <br/> <span>Alice: Revolutionary scaleable technology<br/> for Energy Management</span></span>,
            img: "prizes/vakbeurs.jpg",
            url: "https://www.vakbeursenergie.nl/nl/nieuws-item/AI-algoritme-Alice-bekroond-met-Innovative-Energy-Solutions-Award/"
        },
        {
            name: <span><span>Gerard & Anton Awards 2023</span> <br/> <span>Most promising tech <br/> scale-up BrainPort</span></span>,
            img: "prizes/gaawards.jpg",
            url: "https://innovationorigins.com/en/10-1-gerard-anton-awards-here-are-the-2023-winners/"
        },
        {
            name: <span><span>Best Teacher Data Science and Artificial Intelligence 2022</span> <br/> <span>Nomination</span></span>,
            img: "prizes/tue.png",
            url: "prizes/BestTeacherTue2022.png"
        },
        {
            name: <span><span>Elsevier Weekblad: 30 onder de 30</span> <br/> <span>Jonge uitblinkers om in de gaten te houden</span></span>,
            img: "media/ew30.jpg",
            url: "https://www.ewmagazine.nl/nederland/achtergrond/2020/07/smaakmakers-van-de-komende-decennia-206066w/"
        },
        {
            name:
                <span><span>TU/e Academic Awards 2019</span> <br/> <span>Best Thesis Mathematics & Computer Science</span></span>,
            img: "prizes/tue.png",
            url: "https://flippingbooks.tue.nl/internet/academic_awards_2019/34/"
        },
        {
            name: <span><span>Gerrit van Dijk Data Science 2019</span> <br/> <span>Honorable Mention</span></span>,
            img: "prizes/gerrit.png",
            url: "https://www.linkedin.com/feed/update/urn:li:activity:6546673076246056960"
        },
        {
            name:
                <span><span>European Venture Program Best Executive Summary</span> <br/> <span>Amount: €1.000</span></span>,
            img: "prizes/evp.png",
            url: "https://innovationorigins.com/en/transport-heroes-from-tu-munich-wins-eurotech-venture-pitches/"
        },
        {
            name: <span><span>ICT.Open 2018 Best Demo Award</span> <br/> <span>Amount: €500</span></span>,
            img: "prizes/ictopenaward.png",
            url: "traction/tueict.pdf"
        },
        {
            name: "IEEE Visual Analytics Science and Technology Challenge 2017",
            img: "prizes/vast2.jpg",
            url: "https://medium.com/@TUeindhoven/m-cs-phd-candidate-wins-international-challenge-in-the-field-of-visual-analytics-68594a1d4333"
        }
    ];

    const traction: LinkCardItem[] = [
        {
            name: "Vakbeurs Energie: Tibo",
            img: "media/vakbeurs_media.PNG",
            url: "https://www.vakbeursenergie.nl/nl/nieuws-item/AI-algoritme-Alice-bekroond-met-Innovative-Energy-Solutions-Award/"
        },
        {
            name: "Montea: Tibo",
            img: "media/montea.png",
            url: "https://www.duurzaam-ondernemen.nl/tibo-energy-rolt-geavanceerd-energy-management-systeem-ems-uit-bij-zes-locaties-van-montea-in-belgie/"
        },
        {
            name: "Brainport: Tibo",
            img: "media/brainport.png",
            url: "https://brainporteindhoven.com/the-gate/nl/nieuws/tibo-energy-haalt-eur3m-kapitaal-op"
        },
        {
            name: "Solar Magazine: Tibo",
            img: "media/solar-tibo.jpg",
            url: "https://solarmagazine.nl/nieuws-zonne-energie/i35356/tibo-energy-next-generation-energiemanagement-voor-energiehubs"
        },
        {
            name: "Anton & Gerard: Tibo",
            img: "media/gaa-tibo.jpg",
            url: "https://innovationorigins.com/en/10-1-gerard-anton-awards-here-are-the-2023-winners/"
        },
        {
            name: "Change Inc: Tibo",
            img: "media/change_inc_tibo.png",
            url: "https://www.change.inc/energie/ieder-bedrijf-zijn-eigen-virtuele-energiecentrale-deze-start-up-maakt-het-mogelijk-40191"
        },
        {
            name: "Innovation Origins: Tibo",
            img: "media/io_tibo.png",
            url: "https://innovationorigins.com/en/2023-was-the-year-of-these-10-plus-one-winners-of-the-gerard-and-anton-awards/"
        },
        {
            name: "Computable: Tibo",
            img: "media/tibo_computable.png",
            url: "https://www.computable.nl/artikel/techwire/computable-next/7484028/2499347/tibo-energy-heeft-de-oplossing-om-complexe-energienetwerken-te-managen.html"
        },
        {
            name: "De ondernemer: Tibo",
            img: "media/tibo_ondernemer.png",
            url: "https://www.deondernemer.nl/innovatie/technologie/eindhoven-startup-tibo-energy-problemen-stroomnet~4445664"
        },
        {
            name: "Duurzaam-ondernemen: Tibo",
            img: "media/tibo_duurzaam.png",
            url: "https://www.duurzaam-ondernemen.nl/eerste-smart-grid-management-platform-voor-complexe-bedrijfsterreinen-en-campussen-ziet-het-licht-in-eindhoven/"
        },
        {
            name: "Installatiejournaal: Tibo",
            img: "media/tibo_installatie.png",
            url: "https://www.installatiejournaal.nl/15434/smart-grid-management-digital-twin-technologie?giftCode=GMS3_8_rWlafDMRHaGM5E-4Ucwe953BhPvwa-z8Mx2E=&social=copy"
        },
        {
            name: "Algemeen Dagblad: Tibo",
            img: "media/tibo_ad.png",
            url: "https://www.ad.nl/eindhoven/eindhovense-startup-wil-klikkend-de-problemen-op-het-stroomnet-te-lijf~aab05a64/"
        },
        {
            name: "Cursor: DutchDataBusters",
            img: "media/ddbartikel.png",
            url: "https://www.cursor.tue.nl/achtergrond/2023/maart/week-3/dutchdatabusters-matchmaking-tussen-studenten-en-bedrijven/"
        },
        {
            name: "KPN: European Cyber Security Perspectives 2020",
            img: "media/escp2020.png",
            url: "media/escp2020.pdf"
        },
        {
            name: "PCM: May 2019",
            img: "traction/pcm.png",
            url: "https://issuu.com/computertotaal/docs/pcm_05-2019_preview"
        },
        {
            name: "KPN: European Cyber Security Perspectives 2019",
            img: "media/escp2019.jpg",
            url: "media/escp2019.pdf"
        },
        {
            name: "Innovation Origins: AnalyzeData",
            img: "traction/innovationorigins2.png",
            url: "https://innovationorigins.com/tu-e-start-up-analyzedata-targets-cyber-hazards-with-visual-analytics/"
        },
        {
            name: "Algemeen Dagblad & Eindhoven Dagblad",
            img: "traction/ad.png",
            url: "https://www.ad.nl/eindhoven/bram-cappers-uit-veldhoven-ontwikkelt-methode-om-computercriminaliteit-sneller-op-te-sporen-br~a464bbf4/"
        },
        {
            name: "ICTMagazine",
            img: "traction/ictmagazine.png",
            url: "https://www.ictmagazine.nl/promotie-tu-eindhoven-met-visualisatietool-eventpad-tegen-cyberaanvallen/"
        },
        {
            name: "Innovation Origins: Eventpad",
            img: "traction/innovationorigins.png",
            url: "https://innovationorigins.com/nl/sneller-een-cyberaanval-ontdekken-dankzij-eventpad/"
        },
        {
            name: "Technisch weekblad",
            img: "traction/technischweekblad.png",
            url: "https://www.technischweekblad.nl/nieuws/data-beter-behapbaar-als-blokjes/item12486"
        },
        {
            name: "Computable",
            img: "traction/computable.png",
            url: "https://www.computable.nl/artikel/nieuws/security/6524408/250449/tool-visualiseert-cyberaanval-met-kleurtjes.html"
        },
        {
            name: "Engineers Online",
            img: "traction/engineersonline.png",
            url: "https://www.engineersonline.nl/nieuws/id30749-hack-activiteiten-nu-op-tijd-vindbaar-en-zichtbaar.html"
        },
        {
            name: "Emerce",
            img: "traction/emerce.png",
            url: "https://www.emerce.nl/nieuws/verborgen-cybergevaar-blootgelegd-excelvisualisatie"
        },
        {
            name: "ComputerIdee: Nr 7 2019",
            img: "traction/computeridee.png",
            url: "https://www.computeridee.nl/edities/2019-7/"
        },
        {
            name: "Volkskrant",
            img: "traction/volkskrant.png",
            url: "https://www.volkskrant.nl/nieuws-achtergrond/ook-cybercrimineel-ontdekt-aantrekkelijke-kant-van-kunstmatige-intelligentie-hoe-zorgwekkend-is-dat-~bb9327f8/?referer=https%3A%2F%2Fwww.google.com%2F"
        },
        {
            name: "De Ingenieur",
            img: "traction/ingenieur2.jpg",
            url: "https://www.deingenieur.nl/artikel/hier-is-het-beste-van-2019"
        },
        {
            name: "TU/e Slash",
            img: "traction/slash.png",
            url: "media/slash.pdf"
        },
        {
            name: "TU/e: Eventpad PhD Thesis",
            img: "traction/tue.png",
            url: "https://www.tue.nl/en/news/news-overview/29-11-2018-hidden-cyber-hazard-uncovered-in-time-using-advanced-excel-visualization/"
        },
        {
            name: "Radio Interview: BrainportRadio",
            img: "traction/brainport.png",
            url: "https://www.linkedin.com/feed/update/urn:li:activity:6476458884331499520"
        },
        {
            name: "I/O Magazine",
            img: "traction/I&O.png",
            url: "https://ict-research.nl/wordpress/wp-content/uploads/2019/01/IO-magazine-NR04-december-2018_v4-_web.pdf"
        },
        {
            name: "KPN: European Cyber Security Perspectives 2018",
            img: "media/escp2018.jpg",
            url: "media/escp2018.pdf"
        },
        {
            name: "TU/e: European Venture Program 2018",
            img: "traction/tueevp.png",
            url: "https://innovationorigins.com/nl/munchense-startup-wint-eurotech-pitchwedstrijd-in-eindhoven/"
        },
        {
            name: "Innovation Origins: European Venture Program 2018 Award",
            img: "traction/innovationorigins3.png",
            url: "https://innovationorigins.com/nl/munchense-startup-wint-eurotech-pitchwedstrijd-in-eindhoven/"
        },
        {
            name: "Eventpad @ BlackHat USA 2018",
            img: "traction/tueblackhat.png",
            url: "https://www.blackhat.com/us-18/presenters/Bram-Cappers.html"
        },
        //https://www.tue.nl/en/our-university/departments/wiskunde-en-informatica/news/28-03-2018-tue-phd-candidates-win-ictopen-award-with-visual-analytics-system-eventpad/.
        {
            name: "TU/e: ICT.Open 2018 Best Demo Award",
            img: "traction/tueict.png",
            url: "traction/tueict.pdf"
        },
        {
            name: "Medium: Visual Analytics Science and Technology Award",
            img: "traction/medium.png",
            url: "https://medium.com/@TUeindhoven/m-cs-phd-candidate-wins-international-challenge-in-the-field-of-visual-analytics-68594a1d4333"
        }
    ];

    const sections: SectionItem[] = [
        {
            name: "Company websites",
            items: websites
        },
        {
            name: "Grants",
            items: grants
        },
        {
            name: "Awards",
            items: awards
        },
        {
            name: "Traction",
            items: traction
        }
    ];

    return (<Container fluid className="px-lg-4 px-xl-5">
        {
            sections.map((section,i) => {
                return (
                    <Section key={i} name={section.name}>
                        <GridView items={section.items.map((item) => <LinkCard item={item}/>)}/>
                    </Section>

                );
            })
        }
    </Container>);
}
