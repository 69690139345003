import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import {Col, Container, Image, Nav, Navbar, Row, Table} from "react-bootstrap";
import {Home} from "./routes/Home";
import {Curiculum} from "./routes/Curiculum";
import {NotFound} from "./routes/NotFound";
import {Traction} from "./routes/Traction";
import {Eventpad} from "./routes/Eventpad";

function App() {
    return (
        <div className="App">
            <Container style={{padding: "10px"}}>
                <Row className={"d-flex justify-content-center"}>
                    <Col md={4}>
                        <Image src={"profile2.png"} style={{maxHeight: "300px", margin: "10px"}}/>
                    </Col>
                    <Col className={"my-auto"}>
                        <Table style={{verticalAlign: "center"}}>
                            <tbody>
                            <tr>
                                <td>Name:</td>
                                <td>dr. ir. B.C.M. (Bram) Cappers</td>
                            </tr>
                            <tr>
                                <td>Position:</td>
                                <td>Visualization Architect CodeNext21<br/>Teacher Visual Analytics TU/e
                                </td>
                            </tr>
                            <tr>
                                <td>Address:</td>
                                <td>Eindhoven University of Technology. Department of Mathematics and Computer Science.
                                    Visualization group. De Rondom 70, Room MF 6.071b, 5600 MB Eindhoven, The Netherlands
                                </td>
                            </tr>
                            <tr>
                                <td>E-mail:</td>
                                <td><a href={"mailto:b.c.m.cappers@tue.nl"}>b.c.m.cappers@tue.nl</a>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Router>
                        <Navbar className={"rounded"} bg="dark" variant="dark" expand="lg" style={{
                            margin: "10px",
                            marginBottom : "20px"
                        }}>
                            <Container>
                                <Navbar.Brand href="#home">Bram Cappers</Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto">
                                        <Nav.Link href="/home">Home</Nav.Link>
                                        <Nav.Link href="/cv">Curriculum Vitae</Nav.Link>
                                        <Nav.Link href="/trac">Traction</Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                        <Routes>
                            <Route path='/' element={<Home/>}/>
                            <Route path='/home' element={<Home/>}/>
                            <Route path='/cv' element={<Curiculum/>}/>
                            <Route path='/trac' element={<Traction/>}/>
                            <Route path='/eventpad' element={<Eventpad/>}/>
                            <Route path='*' element={<NotFound/>}/>
                        </Routes>
                    </Router>
                </Row>


                <Navbar className={"rounded"} bg="dark" variant="dark" expand="lg" style={{
                    margin: "10px",
                }}>
                    <Container>
                        <Navbar.Brand><small>©2023 Cappers</small></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href="/home">Home</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </Container>
        </div>
    );
}

export default App;
